import axios from 'axios';
import Vue from 'vue';
import '@/lib/log';
import { computed, ref } from '@vue/composition-api';
import { BaseItemState, BaseUseItemModel } from '@/module/shared/base.use-crud.interface';
import {
  LoadJobValidationModel,
  LoadJobValidationStateName
} from '@/module/api/load-job/validation/load-job.validation.interface';
import { GetItemsOptions, ReportType } from '@/module/api/common';
import { ApplyValidationParams, GetItemParams } from './load-job.validation.interface';
import {
  ApplyFixParams,
  FixerFormParams,
  FixerInterface,
  FixerNameValue,
  FixerUserInputParams
} from '.';
import { createToastInterface } from 'vue-toastification';
import { useFileUtils } from '@/utils';

const { createFileDownloadElement } = useFileUtils();

const toast = createToastInterface({ maxToasts: 3 });

const apiPath = (uuid: string) =>
  `${process.env.VUE_APP_API_BASE_URL}/workspace-ui/load-job/${uuid}/validation`;
const defaultMaxResultLimit = -1;

export class FixerFormManager {
  protected fixers: Partial<Record<FixerNameValue, FixerInterface>> = {};

  add(fixer: FixerInterface): void {
    const name = fixer.getName();
    this.fixers[name] = fixer;
  }

  get(name?: FixerNameValue): FixerInterface | undefined {
    if (!name) {
      return undefined;
    }
    return this.fixers[name];
  }

  getAll(): Partial<Record<FixerNameValue, FixerInterface>> {
    return this.fixers;
  }

  async showForm(name: FixerNameValue, params: FixerFormParams): Promise<void> {
    const fixer = this.get(name);
    if (!fixer) {
      const errMessage = `Fixer form ${name} has not been implemented yet`;
      Vue.$log.error(errMessage);
      return;
    }
    fixer.showForm(params);
  }

  async hideForm(name?: FixerNameValue): Promise<void> {
    const fixer = this.get(name);
    if (!fixer) {
      return;
    }
    fixer.hideForm();
  }
}

export const fixerFormManager = new FixerFormManager();

export const emptyItem = (): LoadJobValidationModel => {
  return {
    uuid: '',
    domoSetUuid: '',
    group: '',
    validationLabel: '',
    fixLabel: '',
    severity: '',
    count: 0,
    state: LoadJobValidationStateName.DEFAULT,
    isFixable: false,
    fixerName: undefined,
    fixerParams: {},
    fixerUserInputParams: {},
    validatorId: '',
    validatorParams: {},
    refType: '',
    createdAt: '',
    updatedAt: ''
  };
};

const state: BaseItemState<LoadJobValidationModel> & any = {
  items: ref<LoadJobValidationModel[]>([]),
  selectedItemId: ref(''),
  selectedItem: ref(emptyItem()),
  createdItemId: ref(''),
  createdItem: ref(emptyItem()),
  currentValidatorIdBeingFixed: ref<string>('')
};

const isFixInProgress = ref<boolean>(false);

export function useLoadJobValidationApi(
  useGlobalQueryParams: Record<any, any> | undefined = undefined
): BaseUseItemModel<LoadJobValidationModel> & any {
  Vue.$log.debug('Loaded with query', useGlobalQueryParams);

  const isLoading = ref(false);

  const emptyLoadJobValidationModel = () => {
    return emptyItem();
  };

  const selectItem = async (uuid: string): Promise<LoadJobValidationModel | undefined> => {
    if (uuid) {
      Vue.$log.debug(`Selecting item ${uuid} from items: `, state.items.value);
      const item = state.items.value.find((i: LoadJobValidationModel) => i.uuid === uuid);
      Vue.$log.debug({ item });
      if (item) {
        state.selectedItemId.value = item?.uuid ? item.uuid : '';
        state.selectedItem.value = item;
        return state.selectedItem.value;
      }
    }
  };

  const selectItemBy = async (
    key: string,
    value: string
  ): Promise<LoadJobValidationModel | undefined> => {
    if (key && value) {
      Vue.$log.debug(`Selecting item ${value} from items: `, state.items.value);
      const item = state.items.value.find((i: any) => i?.[key] === value);
      if (item) {
        state.selectedItemId.value = item?.id ? item.id : '';
        Vue.$log.debug({ selected: item });
        state.selectedItem.value = item;
        return state.selectedItem.value as Promise<LoadJobValidationModel>;
      }
    }
  };

  // There is no creating to be done
  const validItem = (item: LoadJobValidationModel) => {
    if (!item.uuid) {
      return false;
    }
    return true;
  };

  const validUpdateItem = (item: Partial<LoadJobValidationModel>) => {
    return true;
  };

  const verifyItem = async (item: Partial<LoadJobValidationModel>) => {
    // TODO: Add verification
    return true;
  };

  const getItems = async (
    options?: GetItemsOptions
  ): Promise<LoadJobValidationModel[] | undefined> => {
    if (!options?.uuid) {
      const errMessage = 'Cannot get validations without a base item uuid';
      Vue.$log.debug(errMessage);
      return;
    }
    isLoading.value = true;
    const combinedQueryParams = Object.assign(
      {},
      useGlobalQueryParams,
      {
        limit: defaultMaxResultLimit
      },
      options?.query
    );
    axios
      .get(apiPath(options.uuid), { params: combinedQueryParams })
      .then((res) => {
        Vue.$log.debug('Returned from API: ', res.data);
        state.items.value = res.data?._embedded ? res.data._embedded : res.data;
        return options?.raw ? res.data : state.items.value;
      })
      .catch((err) => {
        const errMessage = 'Error loading.';
        Vue.$log.error(errMessage, err);
        return;
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  const addOrReplaceItemInItems = (newItem: LoadJobValidationModel) => {
    if (state.items.value.some((item: LoadJobValidationModel) => item.uuid === newItem.uuid)) {
      state.items.value.forEach((item: LoadJobValidationModel, i: number) => {
        if (item.uuid === newItem.uuid) {
          Vue.$log.debug(`Replacing item in items: ${newItem.uuid}`);
          // Note: we must splice instead of assign to trigger render update.
          state.items.value.splice(i, 1, newItem);
        }
      });
    } else {
      Vue.$log.debug(`Adding domain to items: ${newItem.uuid}`);
      state.items.value.push(newItem);
    }
    if (newItem?.uuid !== undefined && state.selectedItem?.value?.uuid === newItem?.uuid) {
      Vue.$log.debug(`Replacing selected item: ${newItem.uuid}`);
      state.selectedItem.value = newItem;
    }
  };

  const getItem = async (params: GetItemParams): Promise<LoadJobValidationModel | undefined> => {
    if (!params.jobUuid || !params.validationUuid) {
      throw new Error('Can not get item without ID');
    }
    isLoading.value = true;
    return axios
      .get<LoadJobValidationModel>(`${apiPath(params.jobUuid)}/${params.validationUuid}`)
      .then((res) => {
        Vue.$log.debug('Returned from API: ', res.data);
        if (res?.data?.uuid) {
          addOrReplaceItemInItems(res.data);
        }
        return res.data;
      })
      .catch((err) => {
        const errMessage = 'Error loading.';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  // Really we are only allowing fixerUserInputParams
  const fieldsToOmitOnTransaction = [
    'uuid',
    'domoSetUuid',
    'group',
    'validationLabel',
    'fixLabel',
    'severity',
    'count',
    'state',
    'isFixable',
    'fixerName',
    'fixerParams',
    'fixerUserInputParams',
    'validatorId',
    'validatorParams',
    'refType',
    'createdAt',
    'updatedAt',
    'refs',
    'errorLabel'
  ];

  const updateItem = async (
    jobUuid: string,
    item: Partial<LoadJobValidationModel>
  ): Promise<LoadJobValidationModel | undefined> => {
    if (!jobUuid || !item?.uuid) {
      const errMessage = 'Unable to update item without a uuid';
      Vue.$log.error(errMessage);
      return;
    }
    Vue.$log.debug('Updating item: ', item);
    isLoading.value = true;
    const body = { fixerUserInputParams: item.fixerUserInputParams };
    return axios
      .patch<LoadJobValidationModel>(apiPath(jobUuid) + '/' + item.uuid, body, {
        headers: { 'content-type': 'application/json' }
      })
      .then((res) => {
        if ((res?.data && res.status >= 200) || res.status <= 299) {
          Vue.$log.debug('Successfully updated item', res);
          addOrReplaceItemInItems(res.data);
          return res.data;
        } else {
          const errMessage = 'Invalid response updating item';
          Vue.$log.error(errMessage, res);
          return undefined;
        }
      })
      .catch((err) => {
        const errMessage = 'Error caught updating item';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  const fix = async (params: ApplyFixParams): Promise<number | undefined> => {
    if (!params.jobUuid || !params.validation?.uuid || !params.validation?.validatorId) {
      const errMessage = 'Unable to update item without a uuid';
      Vue.$log.error(errMessage);
      return;
    }
    Vue.$log.debug('fixing validation: ', params);
    isLoading.value = true;
    isFixInProgress.value = true;
    state.currentValidatorIdBeingFixed = params.validation?.validatorId;
    return axios
      .post(`${apiPath(params.jobUuid)}/${params.validation.uuid}/fix`)
      .then((res) => {
        if (res.status >= 200 || res.status <= 299) {
          Vue.$log.debug('Successfully applied fix for validation', res);
        } else {
          const errMessage = 'Invalid response applying fix for validation';
          Vue.$log.error(errMessage, res);
        }
        return res.status;
      })
      .catch((err) => {
        const errMessage = 'Error caught applying fix for validation';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        isFixInProgress.value = false;
        state.currentValidatorIdBeingFixed = '';
        isLoading.value = false;
      });
  };

  /**
   * Allows the caller to pass the validation to be updated and have its fix
   * applied.
   *
   * @returns A non-empty string on error, else undefined.
   *
   * TODO: make it so the isloading doesn't flip flop while processing
   */
  const applyFix = async (
    loadJobUuid: string,
    validation: LoadJobValidationModel,
    formData: FixerUserInputParams
  ): Promise<string | undefined> => {
    isFixInProgress.value = true;
    const submitValidationResponse = await updateItem(loadJobUuid, {
      ...validation,
      fixerUserInputParams: formData
    });
    isFixInProgress.value = true;
    if (!submitValidationResponse) {
      const errMessage = 'Unable to add validation';
      Vue.$log.error(errMessage);
      isLoading.value = false;
      isFixInProgress.value = false;
      return errMessage;
    }
    const applyFixResponse = await fix({
      jobUuid: loadJobUuid,
      validation: validation
    });
    if (!applyFixResponse || applyFixResponse < 200 || applyFixResponse > 305) {
      const errMessage = 'Unable to apply fix';
      Vue.$log.error(errMessage);
      isLoading.value = false;
      isFixInProgress.value = false;
      return errMessage;
    }
    isFixInProgress.value = true;
    await getItem({ jobUuid: loadJobUuid, validationUuid: validation.uuid });
    isLoading.value = false;
    isFixInProgress.value = false;
    return undefined;
  };

  const validate = async (params: ApplyValidationParams): Promise<number | undefined> => {
    if (!params?.jobUuid || !params?.validationUuid) {
      const errMessage = 'Unable to validate without a uuid';
      Vue.$log.error(errMessage);
      return;
    }
    Vue.$log.debug('applying "valid" state to validation: ', params.validationUuid);
    isLoading.value = true;
    return axios
      .post(`${apiPath(params.jobUuid)}/${params.validationUuid}/transition/validate`)
      .then((res) => {
        if (res.status >= 200 || res.status <= 299) {
          Vue.$log.debug('Successfully validated', res);
        } else {
          const errMessage = 'Invalid response validating';
          Vue.$log.error(errMessage, res);
        }
        return res.status;
      })
      .catch((err) => {
        const errMessage = 'Error caught validating';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  const invalidate = async (params: ApplyValidationParams): Promise<number | undefined> => {
    if (!params?.jobUuid || !params?.validationUuid) {
      const errMessage = 'Unable to invalidate item';
      Vue.$log.error(errMessage);
      return;
    }
    Vue.$log.debug('applying "invalid" state to: ', params.validationUuid);
    isLoading.value = true;
    return axios
      .post(`${apiPath(params.jobUuid)}/${params.validationUuid}/transition/invalidate`)
      .then((res) => {
        if (res.status >= 200 || res.status <= 299) {
          Vue.$log.debug('Successfully invalidating', res);
        } else {
          const errMessage = 'Invalid response invalidating';
          Vue.$log.error(errMessage, res);
        }
        return res.status;
      })
      .catch((err) => {
        const errMessage = 'Error caught applying fix for validation';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  const ignore = async (params: ApplyValidationParams): Promise<number | undefined> => {
    if (!params?.jobUuid || !params?.validationUuid) {
      const errMessage = 'Unable to apply ignore to validation without a uuid';
      Vue.$log.error(errMessage);
      return;
    }
    Vue.$log.debug('applying ignore to validation: ', params.validationUuid);
    isLoading.value = true;
    return axios
      .post(`${apiPath(params.jobUuid)}/${params.validationUuid}/transition/ignore`)
      .then((res) => {
        if (res.status >= 200 || res.status <= 299) {
          Vue.$log.debug('Successfully ignored validation', res);
        } else {
          const errMessage = 'Invalid response applying ignore to validation';
          Vue.$log.error(errMessage, res);
        }
        return res.status;
      })
      .catch((err) => {
        const errMessage = 'Error caught applying ignore to validation';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  const downloadValidationReport = async (
    loadJobUuid: string,
    reportType: ReportType = ReportType.DETAIL
  ) => {
    Vue.$log.debug('Downloading validation report for load job: ', loadJobUuid);
    if (!loadJobUuid) {
      const errMessage = 'Need a uuid to download the validation report.';
      Vue.$log.error(errMessage);
      return;
    }
    isLoading.value = true;
    return axios
      .get(`${apiPath(loadJobUuid)}/report/${reportType}/download`)
      .then((res) => {
        if ((res?.status && res.status >= 200) || res.status <= 299) {
          Vue.$log.debug('successfully downloaded validation report', res);
          return res.data;
        } else {
          const errMessage = 'Invalid response when trying to download the validation report.';
          Vue.$log.error(errMessage, res);
          return undefined;
        }
      })
      .catch((err) => {
        const errMessage = 'Error caught when trying to retrieve the validation report.';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  const downloadLoadJobValidationReport = async (loadJobUuid: string) => {
    isLoading.value = true;
    const report = await downloadValidationReport(loadJobUuid);
    if (!report) {
      const errMessage =
        'Unable to download validation report. There may not have been any results.';
      toast.error(errMessage);
      return;
    }
    const downloadElement = createFileDownloadElement({
      document,
      blobData: report,
      fileName: 'import-validation-report.csv'
    });

    if (downloadElement?.click) {
      downloadElement.click();
      toast.success('Downloaded report');
      isLoading.value = false;
    } else {
      toast.error('Unable to download report');
      isLoading.value = false;
    }
  };

  // Get items on load - commented out because we want to call specifically with filters from component
  // getItems({ limit: defaultMaxResultLimit });

  return {
    items: computed(() => state.items.value),
    emptyItem: computed(() => emptyItem),
    selectedItem: computed(() => state.selectedItem.value),
    // items: computed(() => state.items),
    selectedItemId: computed(() => state.selectedItemId),
    // selectedItem: computed(() => state.selectedItem),
    createdItemId: computed(() => state.createdItemId),
    createdItem: computed(() => state.createdItem),
    isLoading: computed(() => isLoading.value),
    isFixInProgress: isFixInProgress,
    selectItemBy,
    getItem,
    getItems: getItems,
    updateItem,
    fix,
    applyFix,
    validate,
    invalidate,
    ignore,
    selectItem,
    downloadValidationReport,
    downloadLoadJobValidationReport,
    emptyLoadJobValidationModel
  };
}
