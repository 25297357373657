// INVALID: Either fix has not been attempted for the item, OR the validation has failed.
//          If the fix failed, there will be an errorLabel field filled out
// VALID: The fix has been successfully applied to the item.

export enum LoadJobValidationStateName {
  DEFAULT = 'INVALID',
  INVALID = 'INVALID',
  VALID = 'VALID',
  IGNORE = 'IGNORE'
}

export interface ApplyValidationParams {
  jobUuid: string;
  validationUuid: string;
}

export interface ApplyFixParams {
  jobUuid: string;
  validation: LoadJobValidationModel;
}

export type GetItemParams = ApplyValidationParams;

export enum UserNameTransformerParamsType {
  SUFFIX = 'SUFFIX',
  RANDOM_SUFFIX = 'RANDOM_SUFFIX'
}

export enum TransformerIdValue {
  USER_NAME_TRANSFORMER = 'usernametransformer'
}

// NOTE: the values must match SupportedFixerName on the backend.
export enum FixerNameValue {
  USERNAME = 'username',
  RENAME = 'rename',
  SETEMAILSETTINGS = 'setemailsettings',
  SETCALLWRAPUPREASON = 'setcallwrapupreason',
  NONE164TOE164 = 'none164toe164',
  INBOUNDCAMPAIGNSSETMAXLINESTO1IFGT = 'inboundcampaignsetmaxlinesto1ifgt',
  USERDISABLEMEDIATYPECHAT = 'userdisablemediatypechat',
  USERDISABLEMEDIATYPEEMAIL = 'userdisablemediatypeemail',
  USERDISABLEMEDIATYPESOCIAL = 'userdisablemediatypesocial',
  USERADMINPERMISSIONREMOVEBILLING = 'useradminpermissionremovebilling',
  USERSETMAXALLOWEDONEIFZERO = 'usersetmaxallowedoneifzero'
}

export class FixerValidationEvent {
  name?: FixerNameValue;
  userInputParams?: FixerUserInputParams;
}

export interface FixerInterface {
  getName(): FixerNameValue;

  showForm(params: FixerFormParams): Promise<void>;

  hideForm(): Promise<void>;

  getValidationEvent(formData: any): FixerValidationEvent;
}

export interface FixerFormParams {
  validationUuid: string;
  jobUuid: string;
}

export abstract class FixerBase implements FixerInterface {
  abstract getName(): FixerNameValue;
  abstract showForm(params: FixerFormParams): Promise<void>;
  abstract hideForm(): Promise<void>;
  abstract getValidationEvent(formData: any): FixerValidationEvent;
}

export interface UserNameTransformerParams {
  transformerId: TransformerIdValue.USER_NAME_TRANSFORMER;
  suffix?: string;
  type: UserNameTransformerParamsType;
  length?: number;
  dictionary?: string;
}

export interface FixerUserInputParams {
  params?: Record<string, any>;
}

export interface LoadJobValidationModel {
  uuid: string;
  domoSetUuid: string;
  group: string;
  validationLabel: string;
  fixLabel: string;
  errorLabel?: string;
  severity: string;
  count?: number;
  state: LoadJobValidationStateName;
  isFixable: boolean;
  fixerName: FixerNameValue | undefined;
  fixerParams: any;
  fixerUserInputParams: FixerUserInputParams;
  validatorId: string;
  validatorParams: any;
  refType: string;
  createdAt: string;
  updatedAt: string;
}

export const doesValidationHaveError = (validation: LoadJobValidationModel): boolean =>
  validation.errorLabel != undefined && validation.errorLabel != '';
